import React,  { FC, useEffect } from "react";
import { AppProvider, useSetAppState, useSetUserState } from 'context/index';
import { Routing } from './routing';
import { fetchUser, userIsAuthorized } from 'actions/userActions';

import "./App.scss";

const useGetAuth = async () => {
  const setAppState = useSetAppState();
  const setUserState = useSetUserState();
  useEffect(() => {
    const fetchData = async () => {
      setAppState({ Authenticated: userIsAuthorized(), Authenticating: true });
      
      try {
        const result = await fetchUser();
        if(result && result.data) {
          setUserState(result.data);
        }
      } catch(err) {
      }
      setAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
    };

    fetchData();
  }, [setAppState, setUserState]);
};

const App: FC = () => {
  // update User Info
  useGetAuth();
  return (
    <>
      <AppProvider>
          <Routing />
      </AppProvider>
    </>
  );
};

export default App;
