import { createClient } from "actions/clientActions";
import { useState } from "react";
import { PopOutModal } from "components/PopOutModal";
import { Form, Formik } from "formik";
import TextField from "components/Forms/TextField";
import { Button } from "components/Buttons";
import { RadioField } from "components/RadioField";
import styles from "./styles.module.scss";

export interface CreateClientModalProps {
  onClose: () => void;
}

export const CreateClientModal = ({ onClose }: CreateClientModalProps) => {
  const [createClientError, setCreateClientError] = useState("");
  const [createClientSuccess, setCreateClientSuccess] = useState("");
  const [isDefault, setIsDefault] = useState("0");
  

  const createClientCall = async (values: { [key: string]: string }) => {
    const { name, clientSchema } = values;
    try {
      setCreateClientError("");
      setCreateClientSuccess("");

      const response = await createClient({
        name,
        clientSchema,
        isDefault: parseInt(isDefault),
      });
      if (response.error) {
        setCreateClientError(response.error);
      } else {
        setCreateClientSuccess("Client updated successfully");
      }
    } catch(err) {
        setCreateClientError("There was an error in creating client.");
    }
  };

  const changeRadio = () => {
    setIsDefault(isDefault === "0" ? "1" : "0");
  }

  const initialValues = {
    name: '',
    schemaName: '',
  };

  return (
    <PopOutModal title={"Create Client"} onClose={onClose} error={createClientError} success={createClientSuccess}>
      <Formik initialValues={initialValues} onSubmit={createClientCall}>
        <Form>
          <TextField name="name" type="text" label="Name" required />
          <TextField name="clientSchema" type="text" label="Client Schema" required />
          <RadioField label="Set As Default Client" text={""} value={isDefault} groupName="defaultOption" readOnly onClick={() => changeRadio()} checked={isDefault === "1"}/>
          <div className={styles.saveSection}>
            <Button type="submit">Submit</Button>
          </div>
          <div></div>
        </Form>
      </Formik>
    </PopOutModal>
  );
};
