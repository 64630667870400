import React from "react";
import BaseDataTable from "../BaseDataTable/BaseDataTable";
import { ConnectionDataType, RowType, TableStyleType } from "actions/types";
import ConnectionRow from "components/TableRows/ConnectionRow/ConnectionRow";
import styles from "./styles.module.scss";
import ConnectionUserRow from "components/TableRows/ConnectionUserRow/ConnectionUserRow";

interface ConnectionsTableProps {
  connections: ConnectionDataType[];
  nestedTable?: JSX.Element;
  tableStyles: TableStyleType;
  rowType?: RowType;
}

const ConnectionsTable = ({ connections, tableStyles, nestedTable, rowType = RowType.Connection}: ConnectionsTableProps) => {
  const nestedTableHeaders = ["Connection", "Last Run", "Last Successful Run", "Last Test", "Next Run", "Run", "Test", "Edit", "Website"];
  const connectionPageHeaders =  ["Connection", "Username", "Password", "Queue Type", "Path", "url"]
  const dataRows = connections.map((connection, index) => {
    switch (rowType) {
      case RowType.Connection:
      return (
        <React.Fragment key={connection.id}>
          {index !== 0 && (
            <tr>
              <td colSpan={nestedTableHeaders.length}>
                <div className={styles.horizontalBar}/>
              </td>
            </tr>
          )}
          <ConnectionRow connection={connection} />
        </React.Fragment>
      );
      
      case RowType.ConnectionUser:
        return <ConnectionUserRow key={index} connection={connection} />;
      default:
        return null;
    }
  });
  return (
    <BaseDataTable headers={RowType.Connection === rowType ? nestedTableHeaders : connectionPageHeaders} tableStyles={tableStyles} nestedTable={nestedTable} >
      {dataRows}
    </BaseDataTable>
  );
};

export default ConnectionsTable;
