import React, { FC } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";

import { Clients } from 'pages/Clients';
import { Queues } from 'pages/Queues';
import { Home } from 'pages/Home';
import { Login } from "pages/Login";
import { SignUp } from "pages/SignUp";
import { ActiveDirectorySSO } from "pages/ActiveDirectorySSO";
import { useAppState } from 'context';
import ClientPage from "pages/Client";
import ConnectionPage from "pages/Connection";

export enum RoutePaths {
  Login = "/login",
  SignUp = "/signUp",
  Clients = "/clients",
  Client = "/client/:clientId",
  Connection = "/connection/:connectionId/:clientId",
  Connections = "/connections",
  Queues = "/queues", 
  ActiveDirectorySSO = "/adsso"  
};

const AuthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path={RoutePaths.Clients}>
        <Clients />
      </Route>
      <Route path={RoutePaths.Client}>
        <ClientPage />
      </Route>
      <Route path={RoutePaths.Connection}>
        <ConnectionPage />
      </Route>
      <Route path={RoutePaths.Queues}>
        <Queues />
      </Route>
      <Route path={'*'}>
        <Clients /> 
      </Route>      
    </Switch>
  );
};

export const Routing : FC = () => {
  const appState = useAppState();
  return (
    <Router>
        <Switch>
          <Route path={RoutePaths.SignUp}>
            <SignUp />
          </Route>
          <Route path={RoutePaths.Login}>
            <Login />
          </Route>
          <Route path={RoutePaths.ActiveDirectorySSO}>
            <ActiveDirectorySSO />
          </Route>
          <Route
            render={({ location }) => {
              if(appState.Authenticating && !appState.Authenticated) {
                return <>null</>;
              } else if (appState.Authenticated) {
                return <AuthenticatedRoutes />
              } else {
                return <Redirect
                  to={{
                    pathname: RoutePaths.Login,
                    state: { from: location }
                  }}
                />
              }
            }}
          />
        </Switch>
    </Router>
  );
};
