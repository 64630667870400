import React, { FC } from 'react';
import classNames from 'classnames';
import { Field, ErrorMessage } from 'formik';

import styles from './styles.module.scss';

interface TextFieldProps {
    name: string;
    type: 'email' | 'password' | 'text' | 'textArea';
    label?: string;
    required?: boolean;
    placeholder?: string;
    className?: string;
    labelClassName?: string;
    fieldClassName?: string;
}

const TextArea = (props: any) => {
    return <textarea {...props} />
}

export const TextField : FC<TextFieldProps> = ({name, label, required, type, placeholder, className, labelClassName, fieldClassName }) => {
  return (
    <div className={classNames(styles.container, className)}>
      { (label || required) 
        && (
          <label htmlFor={name} className={classNames(styles.label, labelClassName)}>
            {label}
              {required && (<span className={styles.required}>&emsp;</span>)}
          </label>
        )
      }
      {type === 'textArea' ? 
          (<Field as={TextArea} name={name} placeholder={placeholder} className={classNames(styles.field, fieldClassName)} />)
        : (<Field type={type} name={name} placeholder={placeholder} className={classNames(styles.field, fieldClassName)}  />)
      }
      <div>
        <ErrorMessage name={name} component="div" className={styles.alert} />
      </div>
    </div>
  );
};


export default TextField;