import React, { FC } from 'react';
import LayoutPresentation from './Layout.Presentation';

const Layout :FC = (props) => {
  return (
      <LayoutPresentation {...props} ></LayoutPresentation> 
  );
};

export default Layout;

