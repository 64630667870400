import React, { ReactNode, FC } from "react";
import { Link } from 'react-router-dom';
import classNames from "classnames";

import styles from "./styles.module.scss";

interface IButtonProps {
  children?: ReactNode | string;
  style?: "primary" | "secondary" | "none" | "NavButton" | "formSecondaryButton";
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
}

interface LinkButtonProps extends IButtonProps {
  to: string;
};

export const LinkButton: FC<LinkButtonProps> = ({
  to,
  style= "primary",
  children,
  className,
}) => {
  return (
    <Link
      to={to}
      className={classNames(styles.button, styles.linkButton, style && styles[style], className)}
    >
      {children}
    </Link>
  );
};

export const Button: FC<IButtonProps> = ({
  style = "primary",
  children,
  className,
  onClick = () => {},
  disabled = false,
  type="button"
}) => {
  if (style === 'none') {
    return (<div
      className={className}
      tabIndex={0}
      role="button"
      {...{
        disabled,
        onClick,
        onKeyPress: onClick,
        type
      }}
    >
      {children}
    </div>);
  }

  return (
    <button
      className={classNames(styles.button, style && styles[style], className)}
      {...{
        disabled,
        onClick,
        type
      }}
    >
      {children}
    </button>
  );
};

