import moment from "moment";
import { emptyDataValue } from "./config";
import { ConnectionFieldData, ConnectionParams, ConnectionTypes } from "actions/types";

export enum DateFormat {
  ROW_FORMAT_DATE = 'YYYY-MM-DD',
  ROW_FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm',
  DATE_ONLY_SHORT = 'DD MMM YYYY',
  DATE_TIME_SHORT = 'DD MMM YYYY HH:mm',
  MONTH_DAY_YEAR = 'MMM Do, YYYY',
  MONTH_YEAR = 'MMMM YYYY',
  YEAR = 'YYYY'
}

const ToolRequirements = {
  asana: [ConnectionParams.Gitname, ConnectionParams.Token],
  bitbucket: [ConnectionParams.Gitname, ConnectionParams.Token],
  clubhouse: [ConnectionParams.Gitname, ConnectionParams.Token],
  confluence: [ConnectionParams.Gitname, ConnectionParams.Username, ConnectionParams.Password],
  github: [ConnectionParams.Gitname, ConnectionParams.Token],
  gitlab: [ConnectionParams.Gitname, ConnectionParams.Url, ConnectionParams.Token],
  jira: [ConnectionParams.Gitname, ConnectionParams.Username, ConnectionParams.Password],
  linear: [ConnectionParams.Gitname, ConnectionParams.Token, ConnectionParams.Key],
  slack: [ConnectionParams.Gitname, ConnectionParams.Token],
  trello: [ConnectionParams.Gitname, ConnectionParams.Token, ConnectionParams.Key],
};


export const formatDateLabel = (date: string | null | undefined, dateFormat: DateFormat) => {
  if(!date || !moment(date).isValid()){
    return emptyDataValue;
  }
  return moment(date).format(dateFormat);
}

export const todaysDate = new Date();

export const invalidEmail = (email: string) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const convertYesNoToBool = (selected: string) => {
  return selected === "Yes" ? true : false;
};

export const isUndefined = (input: any) => {
  return input === undefined;
};

export const sortByOrder = (a: any, b: any) => { 
  return a.order < b.order ? -1 : a.order > b.order ? 1 : 0; 
};

export const openInNewTab = (url: string | undefined): void => {
  if (!url) return;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const isCompleted = (status: string | undefined | null): string => {
  switch (status) {
    case "completed":
      return 'completed';
    case "failed":
      return 'failed';
    default:
      return 'noStatus';
  }
}

export const enumToLabelValueArray = (enumeration: any) => {
  return Object.keys(enumeration).map(key => ({
    label: key,
    value: enumeration[key]
  }));
};

export const getToolRequirements = (connectionType: ConnectionTypes) => {
  return ToolRequirements[connectionType] || [];
}
