import { type } from "os";
import * as base from "./lib/baseActions";
import { jwtData, apiEndpoints } from "lib/config";
import { StringLiteral } from "typescript";

export interface ClientDataType {
  id?: number;
  clientSchema: string;
  name: string;
  isDefault: number;
  createdAt?: string;
  updatedAt?: string;
  connections?: Array<ConnectionDataType>;
  queues?: QueueDataType;
};

export interface ConnectionDataType {
  id?: number;
  connectionId?: number;
  clientId: number;
  clientName?: string;
  type: string;
  connectionType?: string;
  useDefaultCredentials?: number;
  gitname?: string;
  schemaName?: string;
  username?: string;
  password?: string;
  token?: string;
  url?: string;
  key?: string;
  createdAt?: string;
  updatedAt?: string;
  queueType?: string;
  path?: string;
  lastQueueId?: string;
  lastQueueStatus?: string;
  lastQueueRunType?: string;
  lastQueueStartAt?: string;
  lastQueueFinishedAt?: string;
  lastSuccessfulQueueId?: string;
  lastSuccessfulQueueStatus?: string;
  lastSuccessfulQueueRunType?: string;
  lastSuccessfulQueueStartAt?: string;
  lastSuccessfulQueueFinishedAt?: string;
  nexttQueueId?: string;
  nexttQueueStatus?: string;
  nextQueueRunType?: string;
  nextQueueStartAt?: string;
  nextQueueFinishedAt?: string;
  lastTestQueueId?: string;
  lastTestQueueStatus?: string;
  lastTestQueueRunType?: string;
  lastTestQueueStartAt?: string;
  lastTestQueueFinishedAt?: string;
  sourceUrl?: string;
}

export interface QueueDataType {
  id?: number;
  clientId: number;
  connectionId: number;
  clientName?: string;
  connectionType?: string;
  userId: number;
  runType: RunType;
  startAt?: string;
  statusLog?: string;
  runLog?: string;
  finishedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  queueId?: string;
  queueStatus?: string;
  queueRunType?: string;
  queueStartAt?: string;
}

export enum ConnectionParams {
  Gitname = "gitname",
  Username = "username",
  Password = "password",
  Token = "token",
  Url = "url",
  Key = "key"
};
  

export type RunConnectionProps = {
  runType: string; 
  queueType: string; 
  schedulePeriod: string; 
  startAt: Date;
}

export interface QueueData {
  total: number;
  queues: QueueDataType[];
}

export enum QueueStatusType {
  Queued = "Queued",
  Started = "Started",
  Completed = "Completed",
  Failed = "Failed"
}

export enum ConnectionTypes {
  Github = "github",
  Jira = "jira",
  Gitlab = "gitlab",
  Trello = "trello",
  Bitbucket = "bitbucket",
  Confluence = "confluence",
  Slack = "slack",
  Asana = "asana",
  Clubhouse = "clubhouse",
  Linear = "linear"
}

export interface ConnectionFieldData {
  name: string;
  type: "text" | "password" | "email" | "textArea";
  label: string;
  param: ConnectionParams;
}


export enum QueueTypes {
  Scheduled = "scheduled",
  Adhoc = "adhoc"
}

export enum RunType {
  Test = "test",
  Load = "load"
}

export enum RowType {
  Client = "client",
  ClientUser = "clientUser",
  Connection = "connection",
  ConnectionUser = "connectionUser",
  Queue = "queue",
}

export interface FieldConfig {
  name: string;
  placeholder: string;
  label: string;
  type: 'text' | 'email' | 'password' | 'textArea' | 'radio' | 'dropDown';
  initialValue?: string;
  options?: { label: string; value: string }[];
}

export type PartialFormValues = Partial<ClientDataType> & Partial<ConnectionDataType> & Partial<QueueDataType>;

export enum ColumnStyle {
  Primary = "primary",
  Secondary = "secondary",
}

export enum TableStyleType {
  Primary = "primary",
  Secondary = "secondary",
  HeaderGreen = "headerGreen",
}

export interface ColumnProps {
  value: string;
  style: ColumnStyle;
}

export enum QueueStatuses {
  Queued = "queued",
  Running = "running",
  Failed = "failed",
  Completed = "completed",
  Cancelled = "cancelled",
}