import { QueueDataType, RowType } from 'actions/types';
import { TableRow } from '../TableRow';
import styles from "./styles.module.scss";
import { emptyDataValue } from 'lib/config';
import { DateFormat, formatDateLabel, isCompleted } from 'lib/commonFunctions';
import classNames from 'classnames';

type QueueRowProps = {
  queue: QueueDataType;
  openLogQueue?: () => void;
};

const QueueRow = ({ queue, openLogQueue }:QueueRowProps) => {

  return (
    <TableRow>
      <td className={styles.borderColumn}>{ queue.clientName || queue.clientId ||  emptyDataValue}</td>
      <td className={styles.borderColumn}>{queue.connectionType || queue.connectionId  || emptyDataValue}</td>
      <td className={styles.borderColumn}>{queue.runType ? queue.runType : emptyDataValue}</td>
      <td className={classNames(styles.borderColumn, styles[isCompleted(queue.queueStatus)], styles.statusClick)} onClick={() => { openLogQueue && openLogQueue(); }}>
        {queue.queueStatus ? queue.queueStatus : emptyDataValue}
      </td>
      <td className={styles.borderColumn}>{queue.id ? queue.id : emptyDataValue}</td>
      <td className={styles.borderColumn}>{formatDateLabel(queue.startAt, DateFormat.ROW_FORMAT_DATE_TIME)}</td>
      <td className={styles.borderColumn}>{formatDateLabel(queue.finishedAt, DateFormat.ROW_FORMAT_DATE_TIME)}</td>
      <td className={styles.borderColumn}>{formatDateLabel(queue.updatedAt, DateFormat.ROW_FORMAT_DATE_TIME)}</td>
      <td className={styles.borderColumn}>{formatDateLabel(queue.createdAt, DateFormat.ROW_FORMAT_DATE_TIME)}</td>
      <td className={styles.borderColumnLast}>{queue.runType ? queue.runType : emptyDataValue}</td> 
    </TableRow>
  )
};

export default QueueRow;
