import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field } from "formik";
import TextField from "components/Forms/TextField";
import { Button } from "components/Buttons";
import { PopOutModal } from "components/PopOutModal";
import styles from "./styles.module.scss";
import { createConnection } from "actions/connectionActions";
import DropDown from "components/DropDown/DropDown";
import { enumToLabelValueArray, getToolRequirements } from "lib/commonFunctions";
import { ConnectionParams, ConnectionTypes, QueueTypes } from "actions/types";
import { RadioField } from "components/RadioField";
import classNames from "classnames";

export interface CreateConnectionModalProps {
  onClose: () => void;
  clientId: string;
}
export const CreateConnectionModal = ({ onClose, clientId }: CreateConnectionModalProps) => {
  const [createConnectionError, setCreateConnectionError] = useState("");
  const [createConnectionSuccess, setCreateConnectionSuccess] = useState("");
  const [connectionType, setConnectionType] = useState<ConnectionTypes>(ConnectionTypes.Github);
  const [queueType, setQueueType] = useState<QueueTypes>(QueueTypes.Adhoc);
  const [useDefaultCredentials, setUseCredential] = useState("0");

  const connectionOptions = enumToLabelValueArray(ConnectionTypes);
  const queueTypeOptions = enumToLabelValueArray({Adhoc: QueueTypes.Adhoc}); // temporary forced adhoc

  const connectionRequirements = useMemo(() => getToolRequirements(connectionType), [connectionType]);

  const changeRadio = () => {
    setUseCredential(useDefaultCredentials === "1" ? "0" : "1");
  }
  
  const createConnectionCall = async (values: { [key: string]: string }) => {
    const { username, password, token, key, path, url, gitname } = values;
    try {
      setCreateConnectionError("");
      setCreateConnectionSuccess("");

      const response = await createConnection({
        clientId: parseInt(clientId),
        username, 
        password, 
        token, 
        key, 
        type: connectionType, 
        path, 
        url,
        gitname,
        useDefaultCredentials: useDefaultCredentials === "1" ? 1 : 0,
        queueType
      }, clientId);
      if (response.error) {
        setCreateConnectionError(response.error);
      } else {
        setCreateConnectionSuccess("Connection updated successfully");
      }
    } catch(err) {
      setCreateConnectionError("There was an error in creating connection.");
    }
  };

  const initialValues = {
    type: '',
    username: '',
    password: '',
    token: '',
    key: '',
    path: '',
    url: '',
    gitname: '',
    queueType: ''
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [showKey, setShowKey] = useState(false);


  return (
    <PopOutModal title={"Create Connection"} onClose={onClose} error={createConnectionError} success={createConnectionSuccess}>
      <Formik initialValues={initialValues} onSubmit={createConnectionCall}>
        <Form>
          <DropDown 
            title="Connection Type"
            options={connectionOptions}
            onChange={value => setConnectionType(value as ConnectionTypes)} 
            value={connectionType}
          />
          
          <div className={connectionRequirements.includes(ConnectionParams.Gitname) ? styles.active : styles.inactive}>
            <TextField name="gitname" type="text" label="Gitname" required />
          <div className={connectionRequirements.includes(ConnectionParams.Username) ? styles.active : styles.inactive}>
            <TextField name="username" type="text" label="Username" required />
          </div>
          <div className={connectionRequirements.includes(ConnectionParams.Password) ? classNames(styles.active, styles.connectionField) : styles.inactive}>
            <TextField name="password" type={showPassword ? "text" :"password"} label="Password" required /> <Button style="none" className={styles.hideButton} onClick={() => { setShowPassword(!showPassword); }}>{!showPassword ? `show` : `hide`}</Button>
          </div>
          <div className={connectionRequirements.includes(ConnectionParams.Token) ? classNames(styles.active, styles.connectionField) : styles.inactive}>
            <TextField name="token" type={showToken ? "text" :"password"} label="Token" required /> <Button style="none" className={styles.hideButton}  onClick={() => { setShowToken(!showToken); }}>{!showToken ? `show` : `hide`}</Button>
          </div>
          <div className={connectionRequirements.includes(ConnectionParams.Key) ? classNames(styles.active, styles.connectionField) : styles.inactive}>
            <TextField name="key" type={showKey ? "text" :"password"} label="Key" required /> <Button style="none" className={styles.hideButton}  onClick={() => { setShowKey(!showKey); }}>{!showKey ? `show` : `hide`}</Button>
          </div>
          <div className={connectionRequirements.includes(ConnectionParams.Url) ? styles.active : styles.inactive}>
            <TextField name="url" type="text" label="URL" required />
          </div>
          </div>

          <RadioField label="Use Default Credentials" text={""} value={useDefaultCredentials} groupName="defaultOption" readOnly onClick={() => changeRadio()} checked={useDefaultCredentials === "1"}/>

          <div className={styles.saveSection}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Formik>
    </PopOutModal>
  );
};
