import { ConnectionDataType, RowType, RunType } from 'actions/types';
import React, { useState } from 'react';
import { TableRow } from '../TableRow';
import styles from './styles.module.scss';
import { ReactComponent as RunArrow } from "assets/svgs/double-run-arrow.svg";
import { ReactComponent as Connect } from "assets/svgs/connect-icon.svg";
import { ReactComponent as Edit } from "assets/svgs/edit.svg";
import { ReactComponent as ExternalLink } from "assets/svgs/external-link.svg";
import { EditConnectionModal } from 'components/EditCreateModals/EditConnectionModal/EditConnectionModal';
import { Link } from 'react-router-dom';
import { RoutePaths } from 'App/routing';
import { addClientToQueue } from 'actions/connectionActions';
import { DateFormat, formatDateLabel, isCompleted } from 'lib/commonFunctions';
import { RunConnectionsModal } from 'components/EditCreateModals/RunConnectionsModal/RunConnectionsModal';
import classNames from 'classnames';
import { ErrorModal } from 'components/ErrorModal';
import { Button } from 'components/Buttons';

type ConnectionsRowProps = {
  connection: ConnectionDataType;
};

const ConnectionRow = ({ connection }:ConnectionsRowProps) => {
  const [isEditCreateModalOpen, setIsEditCreateModalOpen] = useState<boolean>(false);
  const [runConnectionModalOpen, setRunConnectionModalOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [runTypeForModal, setRunTypeForModal] = useState<RunType>(RunType.Test);
  return (
    <>
      <TableRow>
      <td className={styles.titleColumn}>
      {
        connection.id ? (
          <Link
            to={RoutePaths.Connection
              .replace(':connectionId', connection.id.toString())
              .replace(':clientId', connection.clientId.toString())}
          >
            <h2>{connection.type}</h2>
          </Link>
        ) : (
          <div>
            <h2>{connection.type}</h2>
          </div>
        )
      }
      </td>
      <td className={classNames(styles.borderColumn, styles[isCompleted(connection.lastQueueStatus)])}>
        <Button style="none" className={styles.lastTest} onClick={() => setErrorModalOpen(true)}>
          <div>
            {formatDateLabel(connection.lastQueueFinishedAt, DateFormat.ROW_FORMAT_DATE_TIME)}

          </div>
          {connection.lastQueueStatus && <div>
            { connection.lastQueueStatus }
          </div>}
        </Button>
      </td>
      <td className={styles.borderColumn}>
        <div>
        {formatDateLabel(connection.lastSuccessfulQueueFinishedAt, DateFormat.ROW_FORMAT_DATE_TIME)}
        </div>
      </td>
      <td className={classNames(styles.borderColumn, styles[isCompleted(connection.lastTestQueueStatus)])}>
        <div>
        {formatDateLabel(connection.lastTestQueueFinishedAt, DateFormat.ROW_FORMAT_DATE_TIME)}
        </div>
        {connection.lastTestQueueStatus && <div>
          { connection.lastTestQueueStatus }
        </div>}
      </td>
      <td className={styles.borderColumn}>
        <div>
        {formatDateLabel(connection.nextQueueStartAt, DateFormat.ROW_FORMAT_DATE_TIME)}
        </div>
      </td>
      <td>
        <button
          className={styles.tableButton}
          onClick={() => {
            setRunTypeForModal(RunType.Load)
            setRunConnectionModalOpen(true)
          }}
        >
          <RunArrow />
        </button>
      </td>
      <td>
        <button
          className={styles.tableButton}
          onClick={() => {
            setRunTypeForModal(RunType.Test)
            setRunConnectionModalOpen(true)
          }}
      >
          <Connect />
        </button>
      </td>
      <td>
        <button
          className={styles.tableButton}
          onClick={() => setIsEditCreateModalOpen(true)}
        >
          <Edit />
        </button>
      </td>
      <td>
        <a href={connection.sourceUrl} target="_blank" rel="noreferrer" >
          <ExternalLink />
        </a>
      </td>
      {isEditCreateModalOpen && (
        <EditConnectionModal connection={connection} onClose={() => setIsEditCreateModalOpen(false)}/>
      )}
      {runConnectionModalOpen && (
        <RunConnectionsModal connection={connection} onClose={() => setRunConnectionModalOpen(false)} runType={runTypeForModal}/>
      )}
      {errorModalOpen && connection.lastQueueId && (
        <ErrorModal  errorTitle={connection.type} queueId={connection.lastQueueId} onClose={() => setErrorModalOpen(false)}/>
      )}
      </TableRow>
    </>
  )
};

export default ConnectionRow;
