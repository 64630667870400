import Layout from "Layout";
import styles from "./styles.module.scss";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as BackArrow } from "assets/svgs/back-arrow.svg";
import { useGetConnection } from "actions/connectionActions";
import { useGetConnectionQueues } from "actions/queueActions";
import ClientTable from "components/DataTables/ClientTable/ClientTable";
import QueueTable from "components/DataTables/QueueTable/QueueTable";
import { RowType, TableStyleType } from "actions/types";
import { useGetClient } from "actions/clientActions";
import { useMemo, useState } from "react";
import { Button } from "components/Buttons";
import { EditClientModal } from "components/EditCreateModals/EditClientModal/EditClientModal";
import { EditConnectionModal } from "components/EditCreateModals/EditConnectionModal/EditConnectionModal";
import ConnectionsTable from "components/DataTables/ConnectionsTable/ConnectionsTable";
import { RoutePaths } from "App/routing";
import { DateFormat, formatDateLabel } from "lib/commonFunctions";
import TableSections, { DataBlockPair } from "./ConnectionInfoBlock";

interface Params {
  connectionId: string;
  clientId: string;
}

const ConnectionPage = () => {
  const [isEditConnectionModalOpen, setIsEditConnectionModalOpen] = useState<boolean>(false);
  const [isEditClientModalOpen, setIsEditClientModalOpen] = useState<boolean>(false);

  const editConnection = () => {
    setIsEditConnectionModalOpen(true);
  };
  const closeEditConnectionModal = () => {
    setIsEditConnectionModalOpen(false);
  };

  const editClient = () => {
    setIsEditClientModalOpen(true);
  };

  const closeEditClientModal = () => {
    setIsEditClientModalOpen(false);
  };

  const maskString = (input: string) => {
    return '*'.repeat(3);
}


  const { connectionId, clientId } = useParams<Params>();

  const { data: connection, error: connectionError, isValidating: connectionIsLoading } = useGetConnection(connectionId);
  const { data: queues, error: clientQueuesError } = useGetConnectionQueues(connectionId);
  const { data: client, error: clientClientError } = useGetClient(clientId);

  const connectionDataTopRow: DataBlockPair[] = [
    {header: 'username' , value: connection?.username ? maskString(connection.username) : ''},
    {header: 'password' , value: connection?.password ? maskString(connection.password) : ''},
    {header: 'queue type' , value: connection?.queueType? connection?.queueType : ''},
    {header: 'path' , value: connection?.path? connection?.path : ''},
    {header: 'url' , value: connection?.url? connection?.url : ''},
  ];

  const connectionDataBottomRow: DataBlockPair[] = [
    {header: 'token' , value: connection?.token ? maskString(connection.token) : ''},
    {header: 'key' , value: connection?.key ? maskString(connection.key) : ''},
    {header: 'Created' , value: connection?.createdAt ? formatDateLabel(connection?.createdAt, DateFormat.ROW_FORMAT_DATE_TIME) : ''},
    {header: 'Updated' , value: connection?.updatedAt ? formatDateLabel(connection?.updatedAt, DateFormat.ROW_FORMAT_DATE_TIME) : ''},
  ];

  return (
    <Layout>
      <div className={styles.connectionPage}>
        <div className={styles.backButton}>
          <Link to={RoutePaths.Clients} className={styles.NavButton}>
            <BackArrow className={styles.arrowStyles} />
          </Link>
        </div>
        <h3>Connection Details</h3>
        <div className={styles.connectionClientHeader}>
          <div className={styles.tablesSection}>
            <div className={styles.clientConnectionSide}>
              <div className={styles.largeTableSection}>
                <p className={styles.headerText}>Connection</p>
                <h3 className={styles.tableData}>{connection?.type}</h3>
              </div>
              <div className={styles.largeTableSection}>
                <p className={styles.headerText}>Client</p>
                <h3 className={styles.tableData}>{client?.id ? <Link
            to={RoutePaths.Client
              .replace(':clientId', client?.id ? client?.id?.toString() : '')
            }
          >{client?.name}</Link> : client?.name}</h3>
              </div>
            </div>
            <div className={styles.connectionData}>
              <div className={styles.infoRow}>
                <TableSections data={connectionDataTopRow}/>
              </div>
              <div className={styles.infoRow}>
              <TableSections data={connectionDataBottomRow}/>
              </div>
            </div>
          </div>
          <div className={styles.editSections}>
            <Button style="formSecondaryButton" onClick={editConnection}>Edit Connection</Button>
            <Button style="formSecondaryButton" onClick={editClient}>Edit Client</Button>
          </div>
        </div>
        {connection?
          <ConnectionsTable connections={[connection]} tableStyles={TableStyleType.Primary} rowType={RowType.Connection}/>:
          <h2 className={styles.emptyTableText}> no connection found for connection Id {connectionId}</h2>
        }
        {queues?
          <QueueTable queues={queues.queues} tableStyles={TableStyleType.Primary}/>:

          <h2 className={styles.emptyTableText}> no Queues found for connection Id {connectionId}</h2>
        }
      </div>
      {isEditConnectionModalOpen && connection && (
        <EditConnectionModal onClose={closeEditConnectionModal} connection={connection} />
      )}
      {isEditClientModalOpen && client && (
        <EditClientModal onClose={closeEditClientModal} client={client} />
      )}
    </Layout>
  );
};

export default ConnectionPage;
