import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import TextField from "components/Forms/TextField";
import { Button } from "components/Buttons";
import { PopOutModal } from "components/PopOutModal";
import styles from "./styles.module.scss";
import { updateConnection } from "actions/connectionActions";
import DropDown from "components/DropDown/DropDown";
import { enumToLabelValueArray, getToolRequirements } from "lib/commonFunctions";
import { ConnectionParams, ConnectionDataType, ConnectionTypes, QueueTypes, RowType, ConnectionFieldData } from "actions/types";
import { RadioField } from "components/RadioField";
import classNames from "classnames";

interface EditConnectionModalProps {
  connection: ConnectionDataType;
  onClose: () => void;
}

export const EditConnectionModal = ({ connection, onClose }: EditConnectionModalProps) => {
  const [editConnectionError, setEditConnectionError] = useState("");
  const [editConnectionSuccess, setEditConnectionSuccess] = useState("");
  const [connectionType, setConnectionType] = useState<ConnectionTypes>(connection.type as ConnectionTypes);
  const [queueType, setQueueType] = useState<QueueTypes>(QueueTypes.Adhoc);//(connection.queueRunType as QueueTypes); temporary until we have queueRunType in the db forced adhoc
  const [useDefaultCredentials, setUseCredential] = useState("0");
  const connectionOptions = enumToLabelValueArray(ConnectionTypes);

  useEffect(() => {
    if (connection.useDefaultCredentials && `${connection.useDefaultCredentials}` !== useDefaultCredentials ) {
      setUseCredential(`${connection.useDefaultCredentials}` === "1" ? "1" : "0");
    }
  },[connection.useDefaultCredentials])

  const changeRadio = () => {
    setUseCredential(useDefaultCredentials === "1" ? "0" : "1");
  }

  const connectionRequirements = useMemo(() => getToolRequirements(connectionType), [connectionType]);

  const handleEditConnection = async (values: { [key: string]: string }) => {
    if (connection.id) {
      const { username, password, token, key, path, url, gitname } = values;
      const response = await updateConnection(connection.id, {
        clientId: connection.clientId,
        username, 
        password, 
        token, 
        key, 
        type: connectionType, 
        path, 
        url,
        gitname,
        useDefaultCredentials: useDefaultCredentials === "1" ? 1 : 0,
        queueType
      }, `${connection.clientId}`);
      if (response.error) {
        setEditConnectionError(response.error);
      } else {
        setEditConnectionSuccess("Connection updated successfully");
      }
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [showKey, setShowKey] = useState(false);


  const initialValues = {
    username: connection.username || '',
    password: connection.password || '',  
    token: connection.token || '',     
    key: connection.key || '',       
    path: connection.path || '',
    url: connection.url || '',
    gitname: connection.gitname || '',
  };

  return (
    <PopOutModal 
      title={`Edit ${connection.type || ''} Connection`} 
      onClose={onClose} 
      error={editConnectionError} 
      success={editConnectionSuccess}
      subTitle="Client"
      subTitleValue={connection.clientName}
      >
      <Formik initialValues={initialValues} onSubmit={handleEditConnection}>
        <Form>
          <DropDown 
            title="Connection Type"
            options={connectionOptions}
            onChange={value => setConnectionType(value as ConnectionTypes)} 
            value={connectionType}
          />
          <div className={connectionRequirements.includes(ConnectionParams.Gitname) ? styles.active : styles.inactive}>
            <TextField name="gitname" type="text" label="Gitname" required />
          <div className={connectionRequirements.includes(ConnectionParams.Username) ? styles.active : styles.inactive}>
            <TextField name="username" type="text" label="Username" required />
          </div>
          <div className={connectionRequirements.includes(ConnectionParams.Password) ? classNames(styles.active, styles.connectionField) : styles.inactive}>
            <TextField name="password" type={showPassword ? "text" :"password"}  label="Password" required  className={styles.textField} /> <Button style="none" className={styles.hideButton} onClick={() => { setShowPassword(!showPassword); }}>{!showPassword ? `show` : `hide`}</Button>
          </div>
          <div className={connectionRequirements.includes(ConnectionParams.Token) ? classNames(styles.active, styles.connectionField) : styles.inactive}>
            <TextField name="token" type={showToken ? "text" :"password"} label="Token" required  className={styles.textField} /> <Button style="none" className={styles.hideButton}  onClick={() => { setShowToken(!showToken); }}>{!showToken ? `show` : `hide`}</Button>
          </div>
          <div className={connectionRequirements.includes(ConnectionParams.Key) ? classNames(styles.active, styles.connectionField) : styles.inactive}>
            <TextField name="key" type={showKey ? "text" :"password"} label="Key" required  className={styles.textField} /> <Button style="none" className={styles.hideButton}  onClick={() => { setShowKey(!showKey); }}>{!showKey ? `show` : `hide`}</Button>
          </div>
          <div className={connectionRequirements.includes(ConnectionParams.Url) ? styles.active : styles.inactive}>
            <TextField name="url" type="text" label="URL" required />
          </div>
          </div>

          <RadioField label="Use Default Credentials" text={""} value={useDefaultCredentials} groupName="defaultOption" readOnly onClick={() => { changeRadio(); }} checked={useDefaultCredentials === "1"}/>
          <div className={styles.saveSection}>
            <Button type="submit">Save</Button>
          </div>
        </Form>
      </Formik>
    </PopOutModal>
  );
};
