import { PopOutModal } from "components/PopOutModal";
import styles from "./styles.module.scss";
import { useGetQueue } from "actions/queueActions";
import classNames from "classnames";
export interface CreateClientModalProps {
  errorTitle?: string;
  queueId: string;
  onClose: () => void;
}

export const ErrorModal = ({ errorTitle, queueId, onClose}: CreateClientModalProps) => {
  const { data: queueData, error } = useGetQueue(queueId);
  const title = `${queueData?.clientName} - ${queueData?.connectionType} - ${queueData?.runType} -  ${queueData?.queueStatus || `Log`} (${queueData?.startAt})` || `Log`;

  return (
    <PopOutModal title={`${title}`} onClose={onClose}>
      {queueData?.statusLog && <h4>
        {queueData.statusLog}
      </h4>}

      <div className={classNames(styles.scrollableDiv, styles.runLog)}>
          {queueData?.runLog && <div>
          {queueData.runLog}
            </div>}
      </div>
    </PopOutModal>
  );
};
