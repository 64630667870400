import React, { useState } from "react";
import Layout from "Layout";
import styles from "./styles.module.scss";
import {useGetQueues } from "actions/queueActions";
import { ReactComponent as ExpandConnections } from "assets/svgs/direction-double-arrow.svg";
import QueueTable from "components/DataTables/QueueTable/QueueTable";
import { TableStyleType } from "actions/types";
import { Button } from "components/Buttons";
import { set } from "lodash";
import { useHistory, useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import { RoutePaths } from "App/routing";
import useQuery from "hooks/useQuery.hook";

enum PageDirection {
  Forward = 'forward',
  Back = 'back'
}

interface QueuePageParams {
  pageNumber?: string;
}

export const Queues = () => {
  const history = useHistory();
  const query = useQuery();
  const pageNumber  = query.get("pageNumber") || "1";
  const pageSize = 10;

  const { data: queueData, error: queuesError } = useGetQueues({ pageNumber, pageSize });
  const maxPages = queueData?.total ? Math.ceil(queueData?.total / pageSize) : 0;
  const changePage = (direction: PageDirection): string => {
    const adjustment = direction === PageDirection.Forward ? 1 : -1;
    const newPageNumber = Math.max(1, Math.min(parseInt(pageNumber) + adjustment, maxPages));
    return `${RoutePaths.Queues}?pageNumber=${newPageNumber}`;
  }

  return (
    <Layout>
      <div className={styles.queuePage}>
        <h2>Queues</h2>
        {queueData?.queues ?
          <div>
            <QueueTable queues={queueData.queues} tableStyles={TableStyleType.Primary}/>
          </div> :
          <h2> No Queues Found</h2>
        }
        <div className={styles.paginateSection}>
          <Link to={changePage(PageDirection.Back)} className={styles.paginateButtonBack}><ExpandConnections/></Link>
          <div>
            {queueData?.total && 
              <p>Page {pageNumber}/{maxPages}</p>
            }
          </div>
          <Link to={changePage(PageDirection.Forward)} className={styles.paginateButtonForward}><ExpandConnections/></Link>
        </div>
        {queuesError && 
          <div className={styles.errorSection}>
            <h2>
              {typeof queuesError === 'string' ? queuesError : 'There was an issue with getting the queue entries'}
            </h2>
          </div>
        }
      </div>
    </Layout>
  );
}
