
export const baseAPIUrl = process.env.REACT_APP_API_URL;
export const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

// Jwt specific data 
export const jwtData = { 
  refreshUrl: 'refreshAuth',
  authToken: 'authToken',
  refreshToken: 'refreshToken'
};

export const apiEndpoints = {
  login: 'login',
  users: 'users',
  usersMe: 'users/me',
  healthCheckApi: 'healthCheck/api',
  healthCheckDatabase: 'healthCheck/database',
  clients: `clients`,
  getAvailableClients: 'client/availableClients',
  queue: `queue`,
  getQueues: `queue/getQueues`,
  connections: `connections`
};


export const emptyDataValue = "N/A"