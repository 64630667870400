import React, { useState } from "react";
import Layout from "Layout";
import styles from "./styles.module.scss";
import { useGetClients } from "actions/clientActions";
import { CreateClientModal } from "components/EditCreateModals/CreateClientModal/CreateClientModal";
import { ClientDataType, RunType, TableStyleType } from "actions/types";
import ClientTable from "components/DataTables/ClientTable/ClientTable";
import DropdownMenu from "components/DropDown/DropDown";
import ConnectionsTable from "components/DataTables/ConnectionsTable/ConnectionsTable";
import { useHistory, useLocation } from "react-router";
import { Button } from "components/Buttons";
import { RunClientsModal } from "components/EditCreateModals/RunClientsModal/RunClientsModal";


const dropDownOptions = [
  { value: '', label: 'All' },
  { value: 'completed', label: 'Completed' },
  { value: 'failed', label: 'Failed' },
];

const enum UrlParams{
  StatusFilter = 'statusFilter',
  SearchField = 'searchField'
}

export const Clients = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const selectedConnectionStatus = params.get(UrlParams.StatusFilter) || '';
  const search = params.get(UrlParams.SearchField) || '';

  const [isEditCreateModalOpen, setIsEditCreateModalOpen] = useState(false);
  const [openRunClientsModal, setOpenRunClientsModal] = useState(false);

  const { data: clients, error: clientsError, isValidating: isLoading } = useGetClients();

  const updateSelectedConnectionStatus = (value: string) => {
    params.set(UrlParams.StatusFilter, value);
    history.push({ search: params.toString() });
  };

  const updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    params.set(UrlParams.SearchField, value);
    history.push({ search: params.toString() });
  };

  const filteredClients = clients?.filter((client: ClientDataType) => {
    return (
      client.name.toLowerCase().includes(search.toLowerCase()) &&
      (selectedConnectionStatus === '' || client.connections &&
        client.connections.some((connection) => connection.lastQueueStatus === selectedConnectionStatus))
    );
  });

  return (
    <Layout>
      <div className={styles.clientPage}>
        <div className={styles.clientTitleSection}>
          <div className={styles.clientTitle}>
            <h2>Clients</h2>
            <button onClick={() => setIsEditCreateModalOpen(true)} className={styles.addClient}>
              +
            </button>
          </div>
          <div className={styles.searchField}>
            <input 
              type="text"
              value={search}
              onChange={updateSearch}
              placeholder="Search clients"
              className={styles.searchBar}
            />
          </div>
          <div className={styles.dropDownSection}>
            <DropdownMenu options={dropDownOptions} onChange={updateSelectedConnectionStatus} value={selectedConnectionStatus} placeholder="Select Status Filter"/>
          </div> 
        </div>

        <div className={styles.scrollableDiv}>
          <div className={styles.clientTable}>
            {filteredClients &&
              filteredClients.map((client: any, index) => (
                <div key={index} className={styles.section}>
                  <ClientTable 
                    clients={[client]} 
                    tableStyles={TableStyleType.Primary} 
                    nestedTable={
                      <ConnectionsTable 
                      connections={client.connections} 
                      tableStyles={TableStyleType.Primary} 
                      />
                    }
                  />
                </div>
              ))}
          </div>
        </div>
        { clientsError &&
          <div className={styles.errorSection}>
            {clientsError.message}
          </div>
        }
        {isEditCreateModalOpen && (
          <CreateClientModal onClose={() => setIsEditCreateModalOpen(false)}/>
        )}
        {openRunClientsModal && (
          <RunClientsModal onClose={() => setOpenRunClientsModal(false)} runType={RunType.Load}/>
        )}
      </div>
    </Layout>
  );
};
