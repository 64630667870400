import { ClientDataType, RowType, RunType } from 'actions/types';
import React, { useMemo, useState } from 'react';
import { TableRow } from '../TableRow';
import styles from "./styles.module.scss";
import useCalculation from 'hooks/useCalculations.hook';
import { ReactComponent as RunArrow } from "assets/svgs/double-run-arrow.svg";
import { ReactComponent as Connect } from "assets/svgs/connect-icon.svg";
import { ReactComponent as Edit } from "assets/svgs/edit.svg";
import { EditClientModal } from 'components/EditCreateModals/EditClientModal/EditClientModal';
import { Link } from 'react-router-dom';
import { RoutePaths } from 'App/routing';
import { RunClientsModal } from 'components/EditCreateModals/RunClientsModal/RunClientsModal';
import { DateFormat, formatDateLabel, isCompleted } from 'lib/commonFunctions';
import classNames from 'classnames';

type ClientRowProps = {
  client: ClientDataType;
};

const ClientRow = ({ client }:ClientRowProps) => {

  const [isEditCreateModalOpen, setIsEditCreateModalOpen] = useState<boolean>(false);
  const [runClientsModal, setRunClientsModal] = useState(false);
  const [runTypeForModal, setRunTypeForModal] = useState<RunType>(RunType.Test);

  
  const { calculateClientStats } = useCalculation();
  

 

  const { lastRun, lastSuccessfulRun, lastTest, nextRun, lastRunStatus, lastTestStatus } = useMemo(() => {
    if (client) {
      return calculateClientStats(client);
    }
    return {
      lastRun: null,
      lastRunStatus: null,
      lastSuccessfulRun: null,
      lastTest: null,
      lastTestStatus: null,
      nextRun: null,
    };
  }, [client]);

  return (
    <TableRow>
      <td className={styles.titleColumn}>
      <h2>
        {client.id ? (
          <Link
            to={RoutePaths.Client.replace(':clientId', client.id.toString())}
            className={styles.clientTitle}
          >
            {client.name}
          </Link>
        ) : (
          <div className={styles.clientTitle}>
            {client.name}
          </div>
        )}
      </h2>
        <div className={styles.schemaSection}>
          <div className={styles.headerTitle} >Schema Name:</div>
          <div>{client.clientSchema}</div>
        </div>
      </td>
    
      <td className={classNames(styles.borderColumn, styles[isCompleted(lastRunStatus)])}>
        <div>
        {formatDateLabel(lastRun, DateFormat.ROW_FORMAT_DATE_TIME)}
        </div>
        {lastRunStatus && <div>
          { lastRunStatus }
        </div>}
      </td>
      <td className={styles.borderColumn}>
        {formatDateLabel(lastSuccessfulRun, DateFormat.ROW_FORMAT_DATE_TIME)}
      </td>
      <td className={classNames(styles.borderColumn, styles[isCompleted(lastTestStatus)])}>
        <div>
        {formatDateLabel(lastTest, DateFormat.ROW_FORMAT_DATE_TIME)}
        </div>
        {lastTestStatus && <div>
          { lastTestStatus }
        </div>}
      </td>
      <td className={styles.borderColumn}>
        {formatDateLabel(nextRun, DateFormat.ROW_FORMAT_DATE_TIME)}
      </td>
      <td>
        <button
          className={styles.tableButton}
          onClick={() => {
            setRunTypeForModal(RunType.Load)
            setRunClientsModal(true)
          }}
        >
          <RunArrow />
        </button>
      </td>
      <td>
        <button
          className={styles.tableButton}
          onClick={() => {
            setRunTypeForModal(RunType.Test)
            setRunClientsModal(true)
          }}
        >
          <Connect />
        </button>
      </td>
      <td>
        <button
          className={styles.tableButton}
          onClick={() =>setIsEditCreateModalOpen(true)}
        >
          <Edit />
        </button>
      </td>
      {isEditCreateModalOpen && (
        <EditClientModal client={client} onClose={() =>setIsEditCreateModalOpen(false)}/>
      )}
      {runClientsModal && (
          <RunClientsModal onClose={() => setRunClientsModal(false)} client={client} runType={runTypeForModal}/>
        )}
    </TableRow>
  )
};

export default ClientRow;
