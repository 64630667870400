import React, { FC } from "react";

import Header from "./Header";
import { userIsAuthorized } from "actions/userActions";
import ContentWrapper from "./ContentWrapper";
import styles from "./styles.module.scss";

const LayoutPresentation: FC = ({ children }) => {
  return (
    <div className={styles.pageLayout}>
      {userIsAuthorized() && ( <Header />)}
      <ContentWrapper>{children}</ContentWrapper>
    </div>
  );
};

export default LayoutPresentation;
