import React, { FC } from 'react';
import styles from './styles.module.scss'

const ContentWrapper: FC = ({children}) => {
    return (<div className={styles.contentWrapper}>
      <div className={styles.innerWrapper}>
      <div className={styles.innerInnerWrapper}>

      {children}
      </div>
      </div>
    </div>);
};

export default ContentWrapper;