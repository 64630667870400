


// Enter here the user flows and custom policies for your B2C application
// To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
// To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview



const mslaTenant: string = process.env.REACT_APP_MSLA_TENANT || "ollonbedrock";

const userFlowSignUpSignIn = process.env.REACT_APP_MSLA_USER_FLOW_SUSI || "B2C_1_susi";
const userFlowForgotPassword = process.env.REACT_APP_MSLA_USER_FLOW_FORGOT_PASS || "b2c_1_reset";

const authoritySignUpSignIn = `https://${mslaTenant}.b2clogin.com/${mslaTenant}.onmicrosoft.com/${userFlowSignUpSignIn}`;
const authorityForgotPassword = `https://${mslaTenant}.b2clogin.com/${mslaTenant}.onmicrosoft.com/${userFlowForgotPassword}`;

const authorityDomain = `${mslaTenant}.b2clogin.com`;


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md 
 */



let clinetId: string = process.env.REACT_APP_MSLA_CLIENT_ID || '';
let redirectUri: string = process.env.REACT_APP_MSLA_REDIRECT_URL || '';

export const msalConfig = {
    auth: {
        clientId: clinetId,
        authority: authoritySignUpSignIn,
        knownAuthorities: [authorityDomain],
        redirectUri: redirectUri
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if having issues on IE11 or Edge
    }

};



// let b2cScopes: string = process.env.REACT_APP_MSLA_B2C_SCOPES || '';
let b2cScopes: string[] = [`https://${mslaTenant}.onmicrosoft.com/My.Scope`];


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes

https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md

 */
export const loginRequest = {
  scopes: ["openid", "email", "profile",...b2cScopes],
};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest = {
  scopes: [...b2cScopes], 
};

export const forgotPasswordRequest = {
  scopes: [...b2cScopes],    
  authority : authorityForgotPassword,
};


export const acquireTokenRequest = {
  ...loginRequest,  
  authority : authorityForgotPassword,
};






