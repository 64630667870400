import { ConnectionDataType, RowType } from 'actions/types';
import React, { useState } from 'react';
import { TableRow } from '../TableRow';
import styles from './styles.module.scss';
import { emptyDataValue } from 'lib/config';

type ConnectionsUserRowProps = {
  connection: ConnectionDataType;
};

const ConnectionUserRow = ({ connection }:ConnectionsUserRowProps) => {
  
  return (
    <TableRow>
      <td className={styles.titleColumn}>
        <div>
          <h2>{connection.type}</h2>
        </div>
      </td>
      <td>
        {connection.username? connection.username : emptyDataValue}
      </td>
      <td>
        {connection.password? connection.password : emptyDataValue}
      </td>
      <td>
        {connection.queueType? connection.queueType : emptyDataValue}
      </td>
      <td>
        {connection.path? connection.path : emptyDataValue}
      </td>
      <td>
        {connection.url? connection.url : emptyDataValue}
      </td>
    </TableRow>
  )
};

export default ConnectionUserRow;
