import React from 'react';
import styles from "./styles.module.scss";
import classNames from 'classnames'; 

interface DropdownMenuProps {
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
  placeholder?: string; 
  title?: string; 
  initialValue?: string; 
  value?: string; 
}

const DropdownMenu = ({ options, onChange, placeholder, title, initialValue, value }: DropdownMenuProps) => {
  return <div className={classNames(styles.container, styles.dropdownContainer)}>
    {title && <label className={styles.label}>{title}</label>}
    <select value={value || initialValue || ''}  onChange={(e) => onChange(e.target.value)} className={styles.dropDownMenu}>
      <option value='' disabled >{placeholder}</option>
      {options.map((option, index) => (
        <option key={index} value={option.value} className={styles.optionStyle}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
};

export default DropdownMenu;
