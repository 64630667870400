import React from "react";
import Layout from "Layout";
import { SignUpForm } from './SignUpForm';
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { RoutePaths } from "App/routing";

export const SignUp = () => {
  return (
    <Layout>
      <div className={styles.signUpSection}>
        <div className={styles.signUpModal}>
          <h2 className={styles.signUpTitle}> Sign Up</h2>
          <SignUpForm />
          <div className={styles.loginRouteSection}>
            <p>Already have an account? </p>
          <Link to={RoutePaths.Login} className={styles.formSecondaryButton}>
            login
          </Link>
      </div>
        </div>
      </div>
    </Layout>
  );
}
