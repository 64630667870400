import React, { Children, useState } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as BackArrow } from 'assets/svgs/back-arrow.svg';
import { Portal } from 'react-portal'


export interface PopOutModalProps {
  title: string;
  subTitle?: string;
  subTitleValue?: string;
  onClose: () => void;
  children?: React.ReactNode;
  error?: string; 
  success?: string;
}

const PopOutModal = ({ onClose, title, subTitle, subTitleValue, children,  error, success }: PopOutModalProps) => {
  return (
    <Portal>
      <div className={styles.modalBackdrop}>
        <div className={styles.modalContent}>
          <div className={styles.topInfo}>
            <button onClick={onClose} className={styles.closeButton}>
              <BackArrow className={styles.arrowStyles} />
            </button>
            <h2 className={styles.title}> {title}</h2>
            <div className={styles.subTitle}>
              <h4 className={styles.subTitleName}>
                {subTitle}
              </h4>
              <h3 className={styles.subTitleValue}>
                {subTitleValue}
              </h3>
            </div>
          </div>
          <div className={styles.horizontalBar} />

          <div className={styles.formSection}>
            {children}
          </div>
          {error && error !== '' &&
            <div className={styles.error}>
              {error}
            </div>
          }
          {success && success !== '' &&
            <div className={styles.success}>
              {success}
            </div>
          }
        </div>
      </div>
    </Portal>
  );
};

export default PopOutModal;
