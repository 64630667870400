import React, { useState } from 'react';
import { QueueDataType, RowType, TableStyleType } from 'actions/types';
import { BaseDataTable } from '../BaseDataTable';
import QueueRow from 'components/TableRows/QueueRow/QueueRow';
import styles from "./styles.module.scss";
import { ErrorModal } from 'components/ErrorModal';

interface QueueTableProps {
  queues: Array<QueueDataType>;
  nestedTable?: JSX.Element;
  tableStyles: TableStyleType;
}

const QueueTable = ({ queues, nestedTable, tableStyles }:QueueTableProps) => {
  const headers = ["Client", "Connection", "Type", "status", "Id", "Start Time", "End Time", "Last Updated", "Created On", "Queue Type"];
  const [ errorModalOpen, setErrorModalOpen] = useState(false);
  const [ selectedQueue, setSelectedQueue] = useState<QueueDataType | undefined>(undefined);

  const openLogQueue = (selectedQueue: QueueDataType) => {
    setSelectedQueue(selectedQueue);
  }

  const dataRows = queues?.map((queue, index) => 
    <React.Fragment key={queue.id}>
      {index !== 0 && (
        <tr>
          <td colSpan={headers.length}>
            <div className={styles.horizontalBar}/>
          </td>
        </tr>
      )}
      <QueueRow key={queue.id} queue={queue} openLogQueue={() => { return  openLogQueue && openLogQueue(queue)}}  />
        </React.Fragment>
  );
  
  return (
    <BaseDataTable headers={headers} tableStyles={tableStyles} nestedTable={nestedTable} >
      {dataRows}
      {selectedQueue?.id && (
        <ErrorModal  errorTitle={`${selectedQueue.connectionId}`} queueId={`${selectedQueue.id}`} onClose={() => setSelectedQueue(undefined)}/>

      )}
    </BaseDataTable>
  );
}

export default QueueTable;
