import styles from "./styles.module.scss";

interface TableRowProps {
  children?: React.ReactNode;
}

const TableRow = ({children }: TableRowProps) => {
  
  return (
    <tr className={styles.infoRow}>
      {children}
    </tr>
  );
};

export default TableRow;
