import cn from "clsx";
import React, { FC } from "react";
import styles from "./styles.module.scss";

export enum RadioFieldStyle {
  Pill,
  StandardCircle,
}

interface RadioFieldType {
  style?: RadioFieldStyle;
  text: string;
  value: string;
  groupName: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  readOnly?: boolean; 
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

export const RadioField: FC<RadioFieldType> = ({
  style = RadioFieldStyle.StandardCircle,
  value,
  text,
  groupName,
  checked,
  disabled = false,
  onChange,
  onClick,
  label,  
}) => {
  return (
    <div className={styles.radioContainer}>
      {label && (
        <label className={styles.radioLabel} htmlFor={`${value}Label-${groupName}`}>{label}</label>
      )}
      <div className={styles.toggleSection}>
        <input
          className={cn(
            styles.radio,
            disabled && styles.disabled,
            style === RadioFieldStyle.StandardCircle && styles.circleRadio,
            style === RadioFieldStyle.Pill && styles.pill
          )}
          type="radio"
          id={`${value}Value-${groupName}`}
          name={groupName}
          checked={checked}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
        />
        <label htmlFor={`${value}Value-${groupName}`}>{text}</label>
      </div>
    </div>
  );
};
