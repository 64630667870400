import React , { FC } from 'react'
import { loginUser, loginUserData, loginUserResult, userIsAuthorized } from 'actions/userActions';
import { useSetAppState, useAppState, useMergeAppState, useSetUserState } from 'context';
import { Formik, Form, FormikHelpers, useFormikContext } from 'formik';
import { withRouter, useHistory } from 'react-router-dom';
import { Button } from 'components/Buttons';
import { TextField } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import { invalidEmail } from 'lib/commonFunctions';
import { RoutePaths } from 'App/routing';
import { useState, useEffect } from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { captchaSiteKey } from "../../../lib/config";

const useOnLogin = () => {
  const setUserState = useSetUserState();
  const setAppState = useSetAppState();
  const mergeAppState = useMergeAppState();
  const history = useHistory();

  const onSubmit = async ({ email, password, captchaToken } : loginUserData) => {
    mergeAppState({ Authenticating: true });
    try {
      const results: loginUserResult = await loginUser({email, password, captchaToken});

      if(results?.data) {
        const { email, firstName, lastName } = results.data;
        setUserState({ email, firstName, lastName });
        history.push(RoutePaths.Clients);
      }
     
      setAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
        
      return results;
    } catch(err) {
      setAppState({ Authenticated: false, Authenticating: false });
      throw err;
    }
  };

  return onSubmit;
};

export interface LoginFormFields {
  email: string;
  password: string;
  captchaToken: string;
};

export interface LoginFormErrors {
  email?: string;
  password?: string;
};

const initialValues = { 
  email: '', 
  password: '',
  captchaToken: ''
};

const validate = (values: LoginFormFields) => {
  const errors: LoginFormErrors = {}; 

  if (!values.email) {
    errors.email = 'Required';
  } else if (invalidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Less than 8 characters';
  }

  return errors;
};

export const LoginFormComponent : FC = () => {
  const appState = useAppState();
  const onLogin = useOnLogin();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const onSubmit = async (values: LoginFormFields, { setSubmitting }: FormikHelpers<LoginFormFields>) => {
    
    if (!appState.Authenticated) {
      setSubmitting(true);
      setErrorMessage(undefined);
      try {
        await onLogin(values);
        setSubmitting(false); 
      } catch (err) {
        setErrorMessage("Failed to Login.");
        setSubmitting(false); 
      }
    }
  }
  
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
    {({ isSubmitting, setFieldValue }) => (    
        <Form>
          <TextField type="email" name="email" placeholder="Email" label="Email" className={styles.field} labelClassName={styles.labels} />
          <TextField type="password" name="password" placeholder="Password" label="Password" className={styles.field} labelClassName={styles.labels} />
          <div className={styles.submitButtonSection}>
            <Button disabled={isSubmitting} className={styles.submitButton} type="submit">
              Submit
            </Button>
          </div >
          {errorMessage ? <div className={styles.error}>
            {errorMessage}
          </div> : null}
          <div className={styles.submitButtonSection}>
            {captchaSiteKey && (
               <HCaptcha 
                sitekey={captchaSiteKey} 
                onVerify={(token) => { setFieldValue('captchaToken', token); }}
              />
            )}
          </div>
        </Form>
    )}
    </Formik>
  );
};

export const LoginForm = withRouter(LoginFormComponent);
