import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import useSWR from 'swr';
import { mutate } from 'swr';
import { QueueData, QueueDataType } from "./types"

interface QueueParams {
  status?: string;
  afterDate?: string;
  clientId?: string;
  connectionId?: string;
  pageNumber?: string;
  pageSize?: number;
}


export const getQueues = async (bodyParams: QueueParams) => {
  const response = await base.get(apiEndpoints.queue, { params: bodyParams });
  if (response.status !== 200) {
    throw new Error('Network response was not ok');
  }
  return response.data;
};

const getQueue = async (queueId: string) => {
  const response = await base.get(`${apiEndpoints.queue}/${queueId}`)
  if (response.status !== 200) {
    throw new Error('Network response was not ok');
  }
  return response.data;
}

export const createQueue = async (data: QueueDataType) => {
  const result = await base.post(`${apiEndpoints.queue}`, data);
  const queueId = result.id;
  mutate([`${apiEndpoints.queue}/${queueId}`, queueId], result);
  mutate([`${apiEndpoints.queue}`]);

  return result;
}

export const updateQueue = async (queueId: number, data: QueueDataType) => {
  const result = await base.put(`${apiEndpoints.queue}/${queueId}`, data);
  mutate([`${apiEndpoints.queue}/${queueId}`, queueId], result);
  mutate([`${apiEndpoints.queue}`]);

  return result;
}

export const deleteQueue = async (queueId: number) => {
  const result = base.del(`${apiEndpoints.queue}/${queueId}`);
  mutate([`${apiEndpoints.queue}`]);
  return result;
}

type useGetQueuesSWRType = [
  string
];

export const useGetQueues = ({status, afterDate, clientId, connectionId, pageNumber, pageSize}: QueueParams) => {
  const paramsString = JSON.stringify({status, afterDate, clientId, connectionId, pageNumber, pageSize});
  const results = useSWR<QueueData>([`${apiEndpoints.queue}`, paramsString], async () => {
    return await getQueues({status, afterDate, clientId, connectionId, pageNumber, pageSize});
  });

  return results;
}


export const useGetClientQueues = (clientId: string) => {
  const results = useSWR<QueueData>([`${apiEndpoints.getQueues}`, `client`, clientId], async ([key]: useGetQueuesSWRType) => {
    return await getQueues({ clientId });
  });

  return results;
};

export const useGetConnectionQueues = (connectionId: string) => {
  const results = useSWR<QueueData>([`${apiEndpoints.getQueues}`, `connection`, `${connectionId}`], async ([key]: useGetQueuesSWRType) => {
    return await getQueues({ connectionId });
  });

  return results;
};

type useGetQueueSWRType = [
  string,
  string
]

export const useGetQueue = (queueId: string) => {

  const queue = useSWR<QueueDataType>([`${apiEndpoints.queue}/${queueId}`, queueId], async ([key, queueId]: useGetQueueSWRType) => {
    return await getQueue(queueId);
  })

  return queue;
}
