import React from "react";
import { Link } from "react-router-dom";
import { Button } from "components/Buttons";
import { logoutUser } from "actions/userActions";
import { useSetAppState } from "context";
import { RoutePaths } from "App/routing";
import styles from "./styles.module.scss";
import { ReactComponent as LogoutIcon } from 'assets/svgs/logout.svg';
import { ReactComponent as Queue } from 'assets/svgs/queue.svg';
import { ReactComponent as Clients } from 'assets/svgs/clients-icon.svg';

interface NavOption {
  label?: string;
  buttonIcon?: React.ComponentType;
  route: RoutePaths | string;
  style?: string;
  action?: () => Promise<void>;
}

const Header = () => {
  const setAppState = useSetAppState();

  const onLogout = async () => {
    await logoutUser();
    setAppState({ Authenticated: false, Authenticating: false });
  };

  return (
    <header>
      <div className={styles.header}>
        <div className={styles.buttonContainer}>
          <Link to={RoutePaths.Clients} className={styles.NavButton}>
            <Clients className={styles.icon}/>
          </Link>
          <Link to={RoutePaths.Queues} className={styles.NavButton}>
            <Queue className={styles.icon}/>
          </Link>
        </div>
        <div className={styles.logoutButton}>
          <Button onClick={onLogout} style="NavButton">
            <LogoutIcon className={styles.icon}/>
          </Button>
      </div>
      </div>
    </header>
  );
};

export default Header;
