import Layout from "Layout";
import { LoginForm } from "./LoginForm";
import { Button } from "components/Buttons";
import { Link, useHistory } from "react-router-dom";
import { RoutePaths } from "App/routing";
import styles from "./styles.module.scss";

export const Login = () => {
  const history = useHistory();

  return (
    <Layout>
      <div className={styles.loginSection}>
        <div className={styles.loginModal}>
          <div className={styles.loginFields}>
            <h2 className={styles.loginTitle}>Login</h2>
            <LoginForm />
          </div>
          <div className={styles.signUpSection}>
          </div>
        </div>
      </div>
    </Layout>
  );
};
