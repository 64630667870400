import React from 'react';
import styles from "./styles.module.scss";

export interface DataBlockPair {
  header: string;
  value: string;
}

interface ConnectionInfoBlockProps {
  data: DataBlockPair[];
}

const TableSections = ({ data }:ConnectionInfoBlockProps) => {
  return (
    <>
      {data.map((item, index) => (
        <div key={index} className={styles.smallTableSection}>
          <p className={styles.headerText}>{item.header}</p>
          <p className={styles.tableData}>{item.value}</p>
        </div>
      ))}
    </>
  );
}

export default TableSections;
