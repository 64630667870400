import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import useSWR from 'swr';
import { mutate } from 'swr';
import { ClientDataType } from "./types"
import { Connection } from "./connectionActions";

interface RunProps{
  queueType: string;
  runType: string;
}
const getClients = async () => {
  const response = await base.get(apiEndpoints.clients);
  return response.data;
};

const getClient = async (clientId: string) => {
  const response = await base.get(`${apiEndpoints.clients}/${clientId}`)
  return response.data;
}

export const createClient = async (data: ClientDataType) => {
  const result = await base.post(`${apiEndpoints.clients}`, data);
  const clientId = result.id;
  mutate([`${apiEndpoints.clients}/${clientId}`, clientId], result)
  mutate([`${apiEndpoints.clients}`]);

  return result;
}

export const updateClient = async (clientId: number, data: ClientDataType) => {
  const result = await base.put(`${apiEndpoints.clients}/${clientId}`, data);
  mutate([`${apiEndpoints.clients}/${clientId}`, clientId], result);
  mutate([`${apiEndpoints.clients}`]);

  return result;
}

export const deleteClient = async (clientId: number) => {
  const result = base.del(`${apiEndpoints.clients}/${clientId}`);
  mutate([`${apiEndpoints.clients}`]);
  return result;
}

type useGetClientsSWRType = [
  string
];

export const useGetClients = () => {
  const results = useSWR<ClientDataType[]>([`${apiEndpoints.clients}`], async ([key]: useGetClientsSWRType) => {
    return await getClients();
  });

  return results;
} 

type useGetClientSWRType = [
  string,
  string
]

export const useGetClient = (clientId: string) => {

  const client = useSWR<ClientDataType>([`${apiEndpoints.clients}/${clientId}`, clientId], async ([key, clientId]: useGetClientSWRType) => {
    return await getClient(clientId);
  })

  return client;
}

export const runAllConnectionsForClient = async (clientId: number, data: RunProps) => {
  const result = base.post(`${apiEndpoints.clients}/${clientId}/queue`, data);
  mutate([`${apiEndpoints.getQueues}`, `client`, `${clientId}`])
  return result;
}

export const runAllRunnableClients = async (data: RunProps) => {
  const result = base.post(`${apiEndpoints.clients}/queue`, data);
  mutate([`${apiEndpoints.getQueues}`])
  return result;
}
