import * as base from "./lib/baseActions";
import { jwtData, apiEndpoints } from "lib/config";

/// Fetch data for logged in user
const fetchUser = () => {
  return base.get(apiEndpoints.usersMe);
};

export interface loginUserData {
  email: string;
  password: string;
  passwordConfirmation?: string;
  captchaToken: string; 
};

export interface signupUserData {
  email: string;
  password: string;
  passwordConfirmation?: string; 
};

export interface authResult {
  data: {
    authToken: string;
    refreshToken?: string;
  }
}

export interface loginUserResult {
  data: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
};

/// Login
/// data = { email:"login email", password: "login pass" }
const loginUser = async (data : loginUserData) : Promise<loginUserResult> => {
  const { email, password, captchaToken } = data;

  try {
    const loginResult: authResult  = await base.post(apiEndpoints.login, {
      email,
      password,
      captchaToken
    });

    localStorage.setItem(jwtData.authToken, loginResult.data.authToken);
    const { refreshToken } = loginResult.data;
    if (refreshToken) {
      localStorage.setItem(jwtData.refreshToken, refreshToken);
    }

    return fetchUser();
  } catch (err: any) {

    if(err?.status === 400) {
      err.message = "Incorrect Email or Password.";
    }

    return Promise.reject(err);
  }
};

/// logout user
const logoutUser = async () => {
  localStorage.removeItem(jwtData.authToken);
  localStorage.removeItem(jwtData.refreshToken);

  return Promise.resolve();
};

// create login user
// data : { email: "email@email.com", password:"password", confirmPassword:"password" }
const createUser = async (data: signupUserData) => {
  const registrationCheck = checkRegistrationInformation(data);

  if(!registrationCheck.success) {
    return Promise.reject(registrationCheck.errors);
  } else {
    try {
      const userResult = await base.post(
        apiEndpoints.users,
        data
      );
      return userResult;
    } catch (err) {
      return Promise.reject(err?.original?.response);
    }
  }
};

/// check confirmation helper function
const checkRegistrationInformation = (data : signupUserData) => {
  let check : { success: boolean; errors?: any } ={ success:true};
  let errors = [];
  if(!data.email || data.email.length < 5) {
    // does not have email
    errors.push(new Error("You must fill in a valid email"));
  }

  if(!data.password || !data.passwordConfirmation) {
    errors.push(new Error("You must fill in the password and password confirmation field"));    
  } else if (data.password.length < 8) {
    errors.push(new Error("The password must be a minimum length of 8"));
  } else if(data.password !== data.passwordConfirmation) {
    errors.push(new Error("The password and confirmation password must match"));
  }

  if(errors.length > 0) {
    check.success=false;
    check.errors = errors;
  }

  return check;
};

// check if user is Authorized
const userIsAuthorized = () => {
  const initialAuthToken = localStorage && localStorage.getItem(jwtData.authToken);
  return initialAuthToken ? true : false;
}

export {
  fetchUser,
  loginUser,
  logoutUser,
  createUser,
  userIsAuthorized
};
