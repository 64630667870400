import { ClientDataType, RowType } from 'actions/types';
import React, { useMemo, useState } from 'react';
import { TableRow } from '../TableRow';
import styles from "./styles.module.scss";
import { Link } from 'react-router-dom';
import { RoutePaths } from 'App/routing';
import { emptyDataValue } from 'lib/config';
import { DateFormat, formatDateLabel } from 'lib/commonFunctions';

type ClientUserRowProps = {
  client: ClientDataType;
};

const ClientRow = ({ client }:ClientUserRowProps) => {
  
  return (
    <TableRow>
      <td className={styles.titleColumn}>
      <h2>
        {client.id ? (
          <Link
            to={RoutePaths.Client.replace(':clientId', client.id.toString())}
            className={styles.clientTitle}
          >
            {client.name}
          </Link>
        ) : (
          <div className={styles.clientTitle}>
            {client.name}
          </div>
        )}
      </h2>
      </td>
      <td>
        {formatDateLabel(client.createdAt, DateFormat.ROW_FORMAT_DATE_TIME)}
      </td>
      <td>
      {formatDateLabel(client.updatedAt, DateFormat.ROW_FORMAT_DATE_TIME)}

      </td>
    
    </TableRow>
  )
};

export default ClientRow;
