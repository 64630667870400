import { ClientDataType } from "actions/types";
import { emptyDataValue } from "lib/config";

interface ClientStats {
  lastRun: string;
  lastRunStatus?: string;
  lastSuccessfulRun: string;
  lastTest: string;
  lastTestStatus?: string;
  nextRun: string;
}

interface UseCalculationProps {
  calculateClientStats: (client: ClientDataType) => ClientStats;
}

function useCalculation(): UseCalculationProps {
  
  const calculateClientStats = (client: ClientDataType) => {
    const now = new Date();
    let lastRun: string = emptyDataValue;
    let lastRunStatus: string = '';
    let lastSuccessfulRun: string = emptyDataValue;
    let lastTest: string = emptyDataValue;
    let lastTestStatus: string = '';
    let nextRun: string = emptyDataValue;

    if (client.connections) {
      for (const connection of client.connections) {
          if (
            lastRun === emptyDataValue ||
            (connection.lastQueueFinishedAt && new Date(connection.lastQueueFinishedAt) > new Date(lastRun))
          ) {
            lastRun = connection.lastQueueFinishedAt || emptyDataValue;
            lastRunStatus = connection.lastQueueStatus || '';
          }
          if (
            (lastSuccessfulRun === emptyDataValue ||
              (connection.lastSuccessfulQueueFinishedAt &&
               new Date(connection.lastSuccessfulQueueFinishedAt) > new Date(lastSuccessfulRun)))
          ) {
            lastSuccessfulRun = connection.lastSuccessfulQueueFinishedAt || emptyDataValue;
          }
          if (
            (lastTest === emptyDataValue ||
              (connection.lastTestQueueFinishedAt && new Date(connection.lastTestQueueFinishedAt) > new Date(lastTest)))
          ) {
            lastTest = connection.lastTestQueueFinishedAt || emptyDataValue;
            lastTestStatus = connection.lastTestQueueStatus || '';
          }
          if (
            (nextRun === emptyDataValue ||
              (connection.nextQueueStartAt && new Date(connection.nextQueueStartAt) < new Date(nextRun)))
          ) {
            nextRun = connection.nextQueueStartAt || emptyDataValue;
          }
      }
    }

    return { lastRun, lastSuccessfulRun, lastTest, nextRun, lastRunStatus, lastTestStatus };
  };
  return { calculateClientStats };
}
export default useCalculation;
