import React from 'react';
import { ClientDataType, RowType, TableStyleType } from 'actions/types';
import BaseDataTable from '../BaseDataTable/BaseDataTable';
import ClientRow from 'components/TableRows/ClientRow/ClientRow';
import ClientUserRow from 'components/TableRows/ClientUserRow/ClientUserRow';

interface ClientTableProps {
  clients: ClientDataType[];
  nestedTable?: JSX.Element;
  tableStyles: TableStyleType;
  rowType?: RowType;
}

const ClientTable = ({ clients, nestedTable, tableStyles=TableStyleType.Primary, rowType = RowType.Client }: ClientTableProps) => {
  const clientHeaders = ["", "Last Run", "Last Successful Run", "Last Test", "Next Run", "Run", "Test", "Edit"];
  const clientUserHeaders = ["Client", "Token", "Key", "Created At", "Updated At"];
  const dataRows = clients.map((client, index) => {
    switch (rowType) {
      case RowType.Client:
        return <ClientRow key={index} client={client} />;
      case RowType.ClientUser:
        return <ClientUserRow key={index} client={client} />;
      default:
        return null;
    }
  });
  return (
    <BaseDataTable headers={RowType.Client === rowType ? clientHeaders : clientUserHeaders} tableStyles={tableStyles} nestedTable={nestedTable} >
      {dataRows}
    </BaseDataTable>
  );
}

export default ClientTable;
