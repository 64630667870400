import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";

import { loginRequest , forgotPasswordRequest  } from "lib/msalConfig";

import * as base from "./lib/baseActions";


export const signIn = (msalInstance: IPublicClientApplication) => {
    
  msalInstance.loginPopup(loginRequest)
    .then(loginResponse => {
        base.getWSpecificToken(`signleSignIn`, loginResponse.idToken);
        const account = getAccount(msalInstance);    
    }).catch(error => {
      // Error handling
      if (error.errorMessage) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (error.errorMessage.indexOf("AADB2C90118") > -1) {
          msalInstance.loginPopup(forgotPasswordRequest )
            .then(() => {
              window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
              msalInstance.logout();
            })
        }
      }
    });
};

// Sign-out the user
export const signOut = (msalInstance: IPublicClientApplication) => {
  // Removes all sessions, need to call AAD endpoint to do full logout
  msalInstance.logout();
}



export const forgotPassword = (msalInstance: IPublicClientApplication) => {
    
  msalInstance.loginPopup(forgotPasswordRequest)
    .then(response => {
        
        console.log("after forgot password");
        
    }).catch(error => {
      console.log(error);
    });
};



export const getTokenPopup = (msalInstance: IPublicClientApplication, request: SilentRequest) => {
    
  return msalInstance.acquireTokenSilent(request)
    .catch(error => {
      console.log("Silent token acquisition fails. Acquiring token using popup");
      console.log(error);
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenPopup(request)
        .then(tokenResponse => {
          console.log("access_token acquired at: " + new Date().toString());
          return tokenResponse;
        }).catch(error => {
          console.log(error);
        });
    });
}

export const passTokenToApi = (msalInstance: IPublicClientApplication) => {
// Acquires and access token and then passes it to the API call

   const account = getAccount(msalInstance);
  
   const request = {
        ...loginRequest,
        account: account || undefined
   }; 

  getTokenPopup(msalInstance, request)
    .then(tokenResponse => {
        console.log("access_token acquired at: " + new Date().toString());
        try {
          console.log("Request made to Web API:");
       //   callApiWithAccessToken(apiConfig.webApi, tokenResponse.accessToken);
       // TODO
        } catch(err) {
          console.log(err);
        }
    });
}

export const getAccount = (msalInstance: IPublicClientApplication) => {
    
    // need to call getAccount here?
    const currentAccounts = msalInstance.getAllAccounts();
    if (currentAccounts === null) {
        console.log("No accounts detected");
        return null;
    }

    if (currentAccounts.length > 1) {
        // Add choose account code here
        console.log("Multiple accounts detected, need to add choose account code.");
        return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
        return currentAccounts[0];
    }

    return null;
}


export const callMsGraph = async (accessToken: string) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch("https://graph.microsoft.com/v1.0/me", options)
        .then(response => response.json())
        .catch(error => console.log(error));
};







