import React , { FC, useState } from 'react'
import { createUser } from 'actions/userActions';
import { Formik, Form, FormikHelpers } from 'formik';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { Button } from 'components/Buttons';
import { TextField } from 'components/Forms/TextField';
import styles from './styles.module.scss';
import { RoutePaths } from 'App/routing';
import { invalidEmail } from 'lib/commonFunctions';

export interface SignUpFormFields {
  email: string;
  password: string;
  passwordConfirmation: string;
};

export interface SignUpFormErrors {
  email?: string;
  password?: string;
  passwordConfirmation?: string;
};

const initialValues: SignUpFormFields = { 
  email: '', 
  password: '', 
  passwordConfirmation: '' 
};

const validate = (values: SignUpFormFields) => {
  const errors: SignUpFormErrors = {}; 

  if (!values.email) {
    errors.email = 'Required';
  } else if (invalidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Less than 8 characters';
  }

  if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'Confirmation Password does not match';
  }

  return errors;
};

export const SignUpFormComponent : FC = () => {

  const history = useHistory();

  const [signUpError, setSignUpError] = useState<string | null>(null);

  const onSubmit = async (values: SignUpFormFields, { setSubmitting }: FormikHelpers<SignUpFormFields>) => {
    setSignUpError(null);
    setSubmitting(true);
    try {
      const createUserResult = await createUser(values);
      setSubmitting(false);
      if (createUserResult.data) {
        console.log("User was successfully created");
      }
      history.push(RoutePaths.Login);
    } catch (err) {
      setSubmitting(false);
      if (err?.status === 400) {
        setSignUpError('A user for the provided email address already exists');
      } else {
        setSignUpError(`There was an error creating the user. Please try again later.`);
      }
    }
  }
  
  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
      {({ isSubmitting, errors }) => (
          <Form>
            <TextField type="email" name="email" placeholder="Email" label="Email" labelClassName={styles.labels} />
            <TextField type="password" name="password" placeholder="Password" label="Password" labelClassName={styles.labels} />
            <TextField type="password" name="passwordConfirmation" placeholder="Confirmation Password" label="Confirmation Password" labelClassName={styles.labels} />
            <div className={styles.submitButtonSection}>
              <Button disabled={isSubmitting} className={styles.submitButton} type="submit">
                Submit
              </Button>
            </div>
              {signUpError && <div>{signUpError}</div>}
          </Form>
        )}
      </Formik>
    </>
  );
};

export const SignUpForm = withRouter(SignUpFormComponent);
