import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as ExpandConnections } from "assets/svgs/direction-double-arrow.svg";
import { TableStyleType } from 'actions/types';
import classNames from "classnames";

interface DataTableProps {
  tableStyles: TableStyleType;
  headers: string[];
  children?: React.ReactNode;
  nestedTable?: JSX.Element;
}

const tableStylesString = (tableStyles: TableStyleType) => {
  switch (tableStyles) {
    case TableStyleType.Secondary:
      return styles.secondary;
    case TableStyleType.HeaderGreen:
      return styles.headerGreen;
    default:
      return styles.primary;
  }
};

const BaseDataTable = ({ tableStyles, headers,  nestedTable, children }: DataTableProps) => {
  const [showNested, setShowNested] = useState<boolean>(true);
  
  const handleToggleConnections = () => {
    setShowNested(!showNested);
  };
  
  return (
    <div className={styles.tableContainer}>
      <table className={classNames(styles.DataTable, tableStylesString(tableStyles))}> 
        <thead className={styles.tableHeader}>
          <tr>
            {headers.map((title, index) => (
              <th key={index} className={styles.headerTitle}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.tableRows}>
          {children}
          { nestedTable && showNested &&
            <tr>
              <td colSpan={8}>
                <div className={styles.horizontalBar}></div>
                {nestedTable}
              </td>
            </tr>
          }
        </tbody>
      </table>
      {nestedTable && (
        <button onClick={handleToggleConnections} className={styles.tableButton}>
          {showNested ? 
            <div>
              <ExpandConnections/> 
            </div>: 
            <div>
              <ExpandConnections className={styles.closeConnectionsTable}/> 
            </div>
          }
        </button>
      )}
    </div>
  );
}

export default BaseDataTable;
