import React, { useState } from "react";
import { Formik, Form } from "formik";
import TextField from "components/Forms/TextField";
import { Button } from "components/Buttons";
import { PopOutModal } from "components/PopOutModal";
import styles from "./styles.module.scss";
import { addClientToQueue } from "actions/connectionActions";
import DropDown from "components/DropDown/DropDown";
import { enumToLabelValueArray, todaysDate } from "lib/commonFunctions";
import { ConnectionDataType, QueueTypes, RunConnectionProps, RunType } from "actions/types";

interface RunConnectionsModalProps {
  connection: ConnectionDataType;
  runType: RunType;
  onClose: () => void;
}

export const RunConnectionsModal = ({onClose, connection, runType}: RunConnectionsModalProps) => {
  const [runConnectionsError, setRunConnectionsError] = useState("");
  const [runConnectionsSuccess, setRunConnectionsSuccess] = useState("");
  const [queueType, setQueueType] = useState<QueueTypes>(QueueTypes.Adhoc);

  const runTypeOptions = enumToLabelValueArray(RunType);
  const queueTypeOptions = enumToLabelValueArray({Adhoc: QueueTypes.Adhoc}); // temporary forced adhoc

  const enqueueClient = async (values: { [key: string]: string }) => {
    setRunConnectionsSuccess("");
    setRunConnectionsError("");

    if (!connection.id) {  
      setRunConnectionsError('Invalid connection id');
      return;
    }
    
    const data: RunConnectionProps = {
      runType: runType,
      queueType: queueType,
      schedulePeriod: "adhoc", 
      startAt: todaysDate
    };

    try {
        if(connection.id){
          const result = await addClientToQueue(connection.id, data, connection.clientId);
        }
        setRunConnectionsSuccess("Client added to queue successfully");
    } catch (error: any) {
        if (error?.original?.response?.data?.message) {
            setRunConnectionsError(error.original.response.data.message);
        } else {
            setRunConnectionsError('An error occurred');
        }
    }
};


  
  const initialValues = {};

  return (
    <PopOutModal 
      title={`Run ${connection.type} ${runType === RunType.Test ? `Test` : `Load`}`} 
      onClose={onClose} 
      error={runConnectionsError} 
      success={runConnectionsSuccess}
      >
      <Formik initialValues={initialValues} onSubmit={enqueueClient}>
        <Form>
          <DropDown 
            title="Queue Type"
            options={queueTypeOptions}
            onChange={value => setQueueType(value as QueueTypes)} 
            value={queueType}
          />
          <div className={styles.saveSection}>
            <Button type="submit">Run {runType === RunType.Test ? `Test` : `Load`}</Button>
          </div>
        </Form>
      </Formik>
    </PopOutModal>
  );
};
