import * as base from "./lib/baseActions";
import { apiEndpoints } from "lib/config";
import useSWR from 'swr';
import { mutate } from 'swr';
import { ConnectionDataType, RunConnectionProps } from "./types"


export type Connection = {
  client_id: number;
  connection_id: number;
  connection_last_run: string | null;
  type: string;
  queue_id: string | null;
  queue_run_type: string | null;
  queue_start_datetime: string | null;
  queue_status: string | null;
};

const getConnections = async () => {
  return base.get(apiEndpoints.connections);
}

const getConnection = async (connectionId: string) => {
  const result = await base.get(`${apiEndpoints.connections}/${connectionId}`)
  return result.data;
}

export const createConnection = async (data: ConnectionDataType, clientId?: string) => {
  const result = await base.post(`${apiEndpoints.connections}`, data);
  const connectionId = result.id;
  mutate([`${apiEndpoints.connections}/${connectionId}`], result);
  mutate([`${apiEndpoints.connections}`]);

  mutate([`${apiEndpoints.clients}`]);
  clientId && mutate([`${apiEndpoints.clients}/${clientId}`, clientId]);

  return result;
}

export const updateConnection = async (connectionId: number, data: ConnectionDataType, clientId?: string) => {
  const result = await base.put(`${apiEndpoints.connections}/${connectionId}`, data);
  mutate([`${apiEndpoints.connections}/${connectionId}`, connectionId], result);
  mutate([`${apiEndpoints.connections}`]);

  mutate([`${apiEndpoints.clients}/${clientId}`, clientId]);
  mutate([`${apiEndpoints.clients}`]);

  return result;
}

export const deleteConnection = async (connectionId: number) => {
  const result = base.del(`${apiEndpoints.connections}/${connectionId}`);
  mutate([`${apiEndpoints.connections}`]);
  return result;
}

type useGetConnectionsSWRType = [
  string
];

export const useGetConnections = () => {
  const results = useSWR<ConnectionDataType[]>([`${apiEndpoints.connections}`], async ([key]: useGetConnectionsSWRType) => {
    return await getConnections();
  });

  return results;
} 

type useGetConnectionSWRType = [
  string,
  string
]

export const useGetConnection = (connectionId: string) => {

  const client = useSWR<ConnectionDataType>([`${apiEndpoints.connections}/${connectionId}`, connectionId], async ([key, connectionId]: useGetConnectionSWRType) => {
    return await getConnection(connectionId);
  })

  return client;
}

export const addClientToQueue = async (connectionId: number, data: RunConnectionProps, clientId?: number) => {
  const endpoint = `${apiEndpoints.connections}/${connectionId}/queue`;
  const result = await base.post(endpoint, data);
  mutate([`${apiEndpoints.getQueues}`, `connection`, `${connectionId}`]);
  clientId && mutate([`${apiEndpoints.getQueues}`, `client`, `${clientId}`]);
  return result;
}

