import Layout from "Layout";
import { getQueues, useGetClientQueues } from "actions/queueActions";
import { ReactComponent as BackArrow } from "assets/svgs/back-arrow.svg";
import {  useGetClient } from "actions/clientActions";
import styles from "./styles.module.scss";
import React, { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { RoutePaths } from "App/routing";
import { CreateConnectionModal } from "components/EditCreateModals/CreateConnectionModal/CreateConnectionModal";
import { TableStyleType } from "actions/types";
import ClientTable from "components/DataTables/ClientTable/ClientTable";
import ConnectionsTable from "components/DataTables/ConnectionsTable/ConnectionsTable";
import QueueTable from "components/DataTables/QueueTable/QueueTable";
import DropdownMenu from "components/DropDown/DropDown";

interface Params {
  clientId: string;
}
const enum UrlParams{
  StatusFilter = 'statusFilter',
}

const dropDownOptions = [
  { value: '', label: 'All' },
  { value: 'success', label: 'Success' },
  { value: 'failed', label: 'Failed' },
];

const ClientPage = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const { clientId } = useParams<Params>();
  const initialSelectedConnectionStatus = params.get(UrlParams.StatusFilter) || '';

  const [isEditCreateModalOpen, setIsEditCreateModalOpen] = useState<boolean>(false);
  const [selectedConnectionStatus, setSelectedConnectionStatus] = useState<string>(initialSelectedConnectionStatus);

  const updateSelectedConnectionStatus = (value: string) => {
    setSelectedConnectionStatus(value);
    params.set(UrlParams.StatusFilter, value);
    history.push({ search: params.toString() });
  };
 
  const { data: client, error: clientError, isValidating: clientIsLoading } = useGetClient(clientId);
  const { data: queueData, error: queuesError } = useGetClientQueues(clientId);

  const filteredConnections = client?.connections?.filter(connection => {
    return selectedConnectionStatus === '' || connection.lastQueueStatus === `'${selectedConnectionStatus}'`;
  }) || [];

  const filteredQueues = queueData?.queues?.filter(queue => {
    return selectedConnectionStatus === '' || queue.queueStatus === `'${selectedConnectionStatus}'`;
  }) || [];
  

  return (
    <Layout>
      <div className={styles.clientPage}>
        <div className={styles.backButton}>
          <Link to={RoutePaths.Clients} className={styles.NavButton}>
            <BackArrow className={styles.arrowStyles} />
          </Link>
        </div>
        <div className={styles.clientSection}>
          <div className={styles.dropDownSection}>
            <DropdownMenu options={dropDownOptions} onChange={updateSelectedConnectionStatus} value={selectedConnectionStatus} placeholder="Select Status Filter"/>
          </div>
          {client && (
            <div className={styles.section}>
              <ClientTable clients={[client]} tableStyles={TableStyleType.HeaderGreen}/>
            </div>
          )}
          <div className={styles.section}>
            <div className={styles.connectionTitleSection}>
              <h2 className={styles.titleSection}>Connections</h2>
              <button onClick={() =>setIsEditCreateModalOpen(true)} className={styles.addClient}>
                +
              </button>
            </div>
            <div className={styles.hrLine} />
            
            { filteredConnections && filteredConnections.length > 0 ?
              <ConnectionsTable connections={filteredConnections} tableStyles={TableStyleType.Primary}/> :
              <h3 className={styles.errorSection}>No connection entries for {client?.name}</h3> 
            }
            { clientError && 
              <h3 className={styles.errorSection}>Failed to load connections: {clientError?.message}</h3>
            }
          </div>
          <div className={styles.section}>
            <h2 className={styles.titleSection}>Queues</h2>
            <div className={styles.hrLine} />
            { filteredQueues && filteredQueues.length > 0 ?
              <QueueTable queues={filteredQueues} tableStyles={TableStyleType.Primary}/> :
              <h3 className={styles.errorSection}>No queue entries for {client?.name}</h3> 
            }
            { queuesError && 
              <h3 className={styles.errorSection}>Failed to load queues</h3>
            }
          </div>
        </div>
      </div>
      {isEditCreateModalOpen && client && (
        <CreateConnectionModal onClose={() =>setIsEditCreateModalOpen(false)} clientId={clientId}/>
      )}
    </Layout>
  );
};

export default ClientPage;
