import { ClientDataType, } from "actions/types";
import { PopOutModal } from "components/PopOutModal";
import { Form, Formik } from "formik";
import { updateClient } from "actions/clientActions";
import { useEffect, useState } from "react";
import TextField from "components/Forms/TextField";
import { Button } from "components/Buttons";
import { RadioField } from "components/RadioField";
import styles from "./styles.module.scss";


export interface EditClientModalProps {
  client: ClientDataType;
  onClose: () => void;
}

export const EditClientModal = ({ client, onClose }: EditClientModalProps) => {
  const [editClientError, setEditClientError] = useState("");
  const [editClientSuccess, setEditClientSuccess] = useState("");
  const [isDefault, setIsDefault] = useState(client.isDefault ? "1" : "0");

  useEffect(() => {
    setIsDefault(`${client?.isDefault || 0}`);
  },[client?.isDefault])

  const editClient = async (values: { [key: string]: string }) => {
    if (client.id) {
      const { name, clientSchema} = values;
      const response = await updateClient(client.id, {
        name,
        clientSchema,
        isDefault: parseInt(isDefault),
      });
      if (response.error) {
        setEditClientError(response.error);
      } else {
        setEditClientSuccess("Client updated successfully");
      }
    }
  };

  const initialValues = {
    name: client.name,
    clientSchema: client.clientSchema,
  };

  const changeRadio = () => {
    setIsDefault(isDefault === "0" ? "1" : "0");
  }

  return (
    <PopOutModal title={"Edit Client"} onClose={onClose} error={editClientError} success={editClientSuccess}>
      <Formik initialValues={initialValues} onSubmit={editClient}>
        <Form>
          <TextField name="name" type="text" label="Name" required />
          <TextField name="clientSchema" type="text" label="Client Schema" required />
          <RadioField label="Set As Default Client" text={""} value={isDefault} groupName="defaultOption" readOnly onClick={() => changeRadio()} checked={isDefault === "1"}/>
          <div className={styles.saveSection}>
            <Button type="submit">Save</Button>
          </div>
        </Form>
      </Formik>
    </PopOutModal>
  );
};
