import { useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "components/Buttons";
import { PopOutModal } from "components/PopOutModal";
import styles from "./styles.module.scss";
import DropDown from "components/DropDown/DropDown";
import { enumToLabelValueArray } from "lib/commonFunctions";
import { ClientDataType, QueueTypes, RunType } from "actions/types";
import { runAllConnectionsForClient, runAllRunnableClients } from "actions/clientActions";

interface RunClientsModalProps {
  client?: ClientDataType;
  runType: RunType;
  onClose: () => void;
}

export const RunClientsModal = ({onClose, client, runType}: RunClientsModalProps) => {
  const [runClientsError, setRunClientsError] = useState("");
  const [runClientsSuccess, setRunClientsSuccess] = useState("");
  const [queueType, setQueueType] = useState<QueueTypes>(QueueTypes.Adhoc);

  const runTypeOptions = enumToLabelValueArray(RunType);
  const queueTypeOptions = enumToLabelValueArray({Adhoc: QueueTypes.Adhoc}); // temporary forced adhoc

  const executeRunnableClientsWithData = async () => {

    const data ={ runType, queueType} 
    setRunClientsSuccess("");
    setRunClientsError("");

    try {
      if(client?.id){
        await runAllConnectionsForClient(client?.id, data);
      }else{
        await runAllRunnableClients(data);
      }
      setRunClientsSuccess("Clients queued successfully");
    } catch (error: any) {
      if (error.original.response.data.message) {
        setRunClientsError(error.original.response.data.message);
      }else{
        setRunClientsError('An error occurred');
      }
    }
  }
  
  const initialValues = {};

  return (
    <PopOutModal 
      title={client?.id? `Run connections for ${client.name}` : "Run all connections for all clients"} 
      onClose={onClose} 
      error={runClientsError} 
      success={runClientsSuccess}
      >
      <Formik initialValues={initialValues} onSubmit={executeRunnableClientsWithData}>
        <Form>
          <DropDown 
            title="Queue Type"
            options={queueTypeOptions}
            onChange={value => setQueueType(value as QueueTypes)} 
            initialValue={queueType}
          />
          <div className={styles.saveSection}>
            <Button type="submit">Run Connections</Button>
          </div>
        </Form>
      </Formik>
    </PopOutModal>
  );
};
